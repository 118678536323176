.custom-table {
    table {
        > thead {
            position: sticky;
            top: 0;
            z-index: 1;

            > .mat-header-row {
                height: 56px;

                > .mat-sort-header {
                    > .mat-sort-header-container {
                        > .mat-sort-header-content {
                            text-align: unset;
                        }
                    }
                }
            }
        }
    }

    mat-paginator {
        .mat-paginator-container {
            height: 56px;

            .mat-paginator-navigation-first,
            .mat-paginator-navigation-previous,
            .mat-paginator-navigation-next,
            .mat-paginator-navigation-last {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
