@import "./DialogComplaint.scss";
@import "./DialogAlert.scss";
@import "./DialogDetail.scss";
@import "./DialogUpload.scss";
@import "./DialogDatepicker.scss";
@import "./DialogTable.scss";

.dialog-custom {
  max-width: 100%;
}
