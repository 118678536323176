.overlay-dialog-upload {
    width: $dialog-custom-autocomp;

    .dialog-custom-upload {
        ul {
            padding-inline-start: 20px;
            margin-block-start: 5px;
        }
    }
}
