@import "mixins";
@import "variable";
@import "components/components";
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  --red-cdg: #bd0d0e;
  --blue-light: #5586ff;
  --blue-choose: #f1f5ff;
  --bg-F2: #f2f2f2;
  --bg-F5: #f5f5f5;
  --bg-F9: #f9f9f9;
  --bg-FA: #fafafa;
  --bg-FB: #dbdbdb;
  --bg-69: #696969;
  --bg-backdrop: #00000065;
  --bg-chat-EA: #ea9494;
  --bg-chat-94: #94eab6;
  --bg-box-chat-messenger: #ff6b6b;
  --bg-EF: #efefef;
  --color-rating-1: #c11511;
  --color-rating-2: #f47c2c;
  --color-rating-3: #ffe600;
  --color-rating-4: #a6dd4c;
  --color-rating-5: #4cdd7d;
}

html,
body {
  height: 100%;
  font-family: "Sarabun", sans-serif !important;
  overflow-x: hidden;
}

body {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $black;
    -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  }

  ::-webkit-input-placeholder {
    @apply leading-6;
  }

  .select-custom.mat-select-disabled {
    @apply bg-[color:var(--bg-FA)];

    .mat-select-value {
      @apply text-[#00000038];
    }
  }

  input:disabled {
    @apply text-[#00000038] bg-[color:var(--bg-FA)];

    &.mat-datepicker-input {
      @apply text-black;
    }
  }

  mat-form-field {
    &.custom {
      .mat-form-field {
        &-underline {
          @apply relative bottom-auto;
        }

        &-subscript-wrapper {
          @apply static;
        }
      }

      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border: $border-edge;
          @apply rounded-[10px] bg-white items-start pt-[0.4rem];

          .mat-form-field-infix {
            @apply w-full pb-[0.4rem];
          }

          .mat-form-field-suffix {
          }
        }

        .mat-form-field-underline {
          @apply hidden bottom-0 #{!important};
        }
      }

      &.mat-focused {
        .mat-form-field-label {
          @apply text-[unset] #{!important};
        }
      }

      &.space-none {
        .mat-form-field-wrapper {
          @apply pb-0;

          .mat-form-field-subscript-wrapper {
            @apply hidden;
          }
        }
      }
    }
  }

  .mat-menu-item-submenu-trigger {
    @apply pl-8 pr-4;

    .mat-menu-submenu-icon {
      @apply left-4 right-auto origin-center rotate-180 top-auto;
    }
  }

  ng-otp-input {
    .wrapper {
      @apply flex items-center justify-between;

      .otp-input {
        border: $border-edge;
      }
    }
  }

  .mat-checkbox {
    > .mat-checkbox-layout {
      @apply whitespace-normal;

      > .mat-checkbox-inner-container {
        > .mat-checkbox-fram {
        }
      }

      > .mat-checkbox-label {
      }
    }

    &.custom-checkbox {
      @apply block outline-0 border-0 overflow-hidden text-ellipsis text-left decoration-0 max-w-full relative h-full w-full;

      > label {
        @apply px-4 w-full h-full flex items-center;

        .mat-checkbox-inner-container {
          @apply ml-0;
        }

        .mat-checkbox-label {
          @apply line-clamp-1;
        }
      }
    }
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    @apply border-[color:var(--blue-light)] #{!important};
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    @apply text-[color:var(--blue-light)] bg-[color:var(--blue-light)] #{!important};
  }

  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-slide-toggle.mat-checked .mat-ripple-element {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    @apply bg-[color:var(--blue-light)] #{!important};
  }

  .image-filter-blue {
    filter: invert(0.5) sepia(1) saturate(45) hue-rotate(200deg);
  }

  @each $count in 1, 2, 3, 4, 5, 6, 7, 8, 9 {
    .line-clamp-#{$count} {
      display: -webkit-box;
      -webkit-line-clamp: $count;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }
  }

  .input-custom {
    @apply leading-[3rem] text-base font-bold px-4 mt-1 h-12 w-full border rounded-lg;

    mat-datepicker-toggle {
      .mat-button-wrapper {
        @apply flex items-center justify-center;
      }
    }
  }

  .mat-input-element-fix {
    box-sizing: inherit !important;
    @apply mt-1 h-12 w-full rounded-lg border px-4 text-base font-bold leading-[3rem] border-solid border-[#e7e7e7] #{!important};
  }

  .select-opt {
    background: url("data:image/svg+xml,<svg height='12px' width='12px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    @apply leading-[3rem] text-base font-bold px-4 h-12 w-full border rounded-lg;
  }

  .select-custom {
    @apply flex flex-col items-center leading-[3rem] text-base font-bold px-0 mt-1 h-12 w-full border rounded-lg;

    .mat-select-trigger {
      @apply flex-1 px-4;
    }
  }

  .select-filter {
    .mat-filter {
      @apply p-0 border-b-0;

      > div {
        @apply flex items-center relative;

        > input {
          @apply flex-1 w-full border border-inherit rounded-lg border-solid h-12 leading-[3rem] text-base font-bold px-4;
        }
      }

      > .noResultsMessage {
        @apply mt-0 p-4;
      }
    }
  }

  .button-cdg {
    @apply rounded-md text-base font-bold min-w-[95px] h-[40px];
  }

  .button-action {
    @apply rounded-lg py-1.5 bg-gray-100 hover:bg-gray-200 w-[40px] h-[40px] min-w-[40px] p-0 flex items-center justify-center;
  }

  .bg-red-cdg {
    @apply text-white bg-[color:var(--red-cdg)];
  }

  .date-bg-red-cdg {
    @apply text-black bg-[color:#ECEDEE] #{!important};
  }

  .bg-bule-light {
    @apply text-white bg-[color:var(--blue-light)];
  }

  .icon-ripper-center {
    .mat-button-wrapper {
      @apply flex items-center justify-center;
    }
  }

  .card-detail {
    @apply bg-white rounded-md overflow-hidden;
    box-shadow: 0px 0px 4px rgb($black, 10%);
  }

  .disable {
    @apply cursor-none opacity-40;
  }

  .border-error {
    @apply border-red-500 #{!important};
  }

  .NgxEditor {
    @apply text-[#696969] #{!important};
  }

  .active-menu-teb {
    @apply border-b-[color:var(--blue-light)] text-[color:var(--blue-light)];
  }

  .active-Tab {
    > .card-list-menu {
      @apply text-white bg-[color:var(--blue-light)] #{!important};

      .image-filter-blue {
        filter: brightness(0) invert(1);
      }
    }
  }

  .dox-chat-messenger {
    .left-box {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 15px solid var(--bg-F5);
        top: 12px;
        left: -15px;
      }
    }

    .right-box {
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 15px solid var(--bg-box-chat-messenger);
        top: 12px;
        right: -15px;
      }
    }
  }

  .chat-tag-suss {
    @apply text-white bg-[color:var(--bg-chat-94)] #{!important};
  }

  .chat-tag-not {
    @apply text-white bg-[color:var(--bg-chat-EA)] #{!important};
  }

  .editor {
    @apply border rounded-[4px];

    .NgxEditor__MenuBar {
      @apply border-b gap-1 p-2;

      > * {
        @apply border border-black/50;

        input[type="text"],
        input[type="href"] {
          @apply border border-black/50 rounded h-8 px-2 leading-8;
        }

        button {
          @apply border border-black/50 rounded px-3 py-1;
        }
      }
    }

    .NgxEditor {
      @apply border-none;

      > .NgxEditor__Content {
        @apply leading-normal text-base font-normal resize-none break-words min-h-[260px];
      }
    }
  }

  // .scroll-hidden {
  //     padding: 0 0 0 8px;
  //     mask-size: 100% 20000px;
  //     mask-position: left bottom;
  //     -webkit-mask-image: linear-gradient(to top, transparent, $black),
  //         linear-gradient(to left, transparent 8px, $black 8px);
  //     -webkit-mask-size: 100% 20000px;
  //     -webkit-mask-position: left bottom;
  //     transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  //     &::-webkit-scrollbar {
  //         width: 8px;
  //     }

  //     &::-webkit-scrollbar-track {
  //         background: transparent;
  //     }

  //     &:hover {
  //         -webkit-mask-position: left top;

  //         &::-webkit-scrollbar-thumb {
  //             background: #c7c7c7;
  //             border-radius: 10px;
  //         }
  //     }
  // }
}
