.overlay-dialog-detail {
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: 0;
  height: 100%;
  // transform: translate3d(100%, 0, 0);
  transition: transform 0.5s ease;
  opacity: 0;

  &.active {
    // transform: translate3d(0%, 0, 0);
    width: 100%;
    opacity: 1;
  }

  > .mat-dialog-container {
    @apply p-0 rounded-[0px];

    .dialog-custom-detail {
    }
  }
}
