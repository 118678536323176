menu-bar {
    position: relative;
    width: auto;
    > .body {
        display: flex;
        overflow: hidden;
        > .bar {
            background-color: #ffffff;
            height: 100vh;
            width: 60px;
            display: flex;
            position: relative;
            padding: 20px;
            flex-direction: column;
            justify-content: space-between;
            border-right: $border-color;
            left: 0px;
            > .head {
                cursor: pointer;
                > svg {
                    > path {
                        transition: 0.4s;
                    }
                }
                &:hover {
                    > svg {
                        > path {
                            transition: 0.4s;
                            fill: rgb(255, 84, 84);
                        }
                    }
                }
            }

            > .active {
                > svg {
                    > path {
                        fill: rgb(255, 84, 84);
                    }
                }
                > svg {
                    > g {
                        > path {
                            fill: rgb(255, 84, 84);
                        }
                    }
                }
            }

            > .footer {
                > .svgfooter {
                    margin-top: 20px;
                    cursor: pointer;
                    > svg {
                        > g {
                            > path {
                                transition: 0.4s;
                            }
                        }
                    }
                    &:hover {
                        > svg {
                            > g {
                                > path {
                                    transition: 0.4s;
                                    fill: rgb(255, 84, 84);
                                }
                            }
                        }
                    }
                }
            }
        }

        .application-block {
            width: 0px;
            opacity: 0;
            height: 100vh;
            background-color: white;
            border-right: $border-color;
            padding: 0px;
            transition: 0.4s;
            position: relative;
            > .title {
                font-family: Sarabun;
                font-style: normal;
                font-weight: bold;
                font-size: 22.4px;
                line-height: 29px;
                min-width: 320px !important;
                opacity: 0;
                transition: 0.2s;
            }
        }

        .setting-block {
            width: 0;
            opacity: 0;
            height: 100vh;
            background-color: white;
            border-right: $border-color;
            padding: 0px;
            right: -0px;
            transition: 0.4s;
            position: absolute;
            > .title {
                font-family: Sarabun;
                font-style: normal;
                font-weight: bold;
                font-size: 22.4px;
                line-height: 29px;
                min-width: 320px !important;
                opacity: 0;
                transition: 0.2s;
            }
        }

        .slide {
            width: 320px;
            opacity: 1;
            transition: 0.4s;
            padding: 20px;
            > .title {
                opacity: 1;
                transition: 0.2s;
            }
        }

        .slide-absolute {
            width: 320px;
            opacity: 1;
            position: absolute;
            transition: 0.4s;
            padding: 20px;
            right: -320px;
            > .title {
                opacity: 1;
                transition: 0.2s;
            }
        }
    }
}
