.main-page {
    > .main-page-left {
    }

    > .main-page-right {
        margin-left: $width-menu;
        transition: all ease 0.3s;

        &.active {
            margin-left: $width-menu-open;
        }
    }
}
