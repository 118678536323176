.overlay-dialog-datepicker {
  width: $dialog-custom-autocomp;

  > .mat-dialog-container {
    @apply overflow-visible p-0;

    .wrapper-time {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
