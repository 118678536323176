.report-page {
  .custom-datepicker-size .mat-datepicker-toggle-default-icon {
    width: 20px;
    height: 20px;
  }

  .custom-datepicker-size .mat-icon-button {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
}

.checkbox-hide {
  mat-pseudo-checkbox {
    display: none;
  }
}
