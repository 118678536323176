.autocomplete-chips-custom {
    .mat-chip-list {
        overflow-y: auto;
        scrollbar-color: transparent transparent;
        scrollbar-width: none;

        /* width */
        &::-webkit-scrollbar {
            display: none;
        }

        // /* Track */
        // &::-webkit-scrollbar-track {
        //     // box-shadow: inset 0 0 5px grey;
        //     // border-radius: 10px;
        // }

        // /* Handle */
        // &::-webkit-scrollbar-thumb {
        //     background: red;
        //     border-radius: 10px;
        // }

        // /* Handle on hover */
        // &::-webkit-scrollbar-thumb:hover {
        //     background: #b30000;
        // }

        > .mat-chip-list-wrapper {
            margin: 0;
            width: 100%;
            height: 100%;
            flex-wrap: unset;
        }
    }
}
