.login-page {
    background: linear-gradient(rgba($black, 0.4), rgba($black, 0.4)),
        url("https://s3.ap-southeast-1.amazonaws.com/chanuntorn.com/BannerCSR.jpg");
    background-position: 50% 50%;
    background-size: 150% 150%;
    transition: background-position 0.4s ease;

    @include media-breakpoint-down(lg) {
        background-size: cover;
    }

    &.step-2 {
        background-position: 65% 50%;
        animation: step2 0.4s ease 1;

        @keyframes step2 {
            0% {
                background-position: 50% 50%;
            }
            100% {
                background-position: 65% 50%;
            }
        }
    }

    &.step-3 {
        background-position: 80% 50%;
        animation: step3 0.4s ease 1;

        @keyframes step3 {
            0% {
                background-position: 65% 50%;
            }
            100% {
                background-position: 80% 50%;
            }
        }
    }

    &.step-4 {
        background-position: 95% 50%;
        animation: step4 0.4s ease 1;

        @keyframes step4 {
            0% {
                background-position: 80% 50%;
            }
            100% {
                background-position: 95% 50%;
            }
        }
    }

    .wrapper-login {
        height: 500px;

        > .wrapper-left {
            width: 342px;
            background: url("https://s3.ap-southeast-1.amazonaws.com/chanuntorn.com/Rectangle+20118.png");
            background-position: 50% 50%;
            background-size: cover;
        }

        > .wrapper-right {
            width: 430px;
        }
    }
}
