@function breakpoint-next($name, $points: $breakpoints, $breakpoint-names: map-keys($points)) {
    $n: index($breakpoint-names, $name);
    @if not $n {
        @error "breakpoint `#{$name}` not found in `#{$points}`";
    }
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $points: $breakpoints) {
    $min: map-get($points, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $points: $breakpoints) {
    $max: map-get($points, $name);
    @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $points: $breakpoints) {
    $min: breakpoint-min($name, $points);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $points: $breakpoints) {
    $max: breakpoint-max($name, $points);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $points: $breakpoints) {
    $min: breakpoint-min($lower, $points);
    $max: breakpoint-max($upper, $points);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($lower, $points) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $points: $breakpoints) {
    $min: breakpoint-min($name, $points);
    $next: breakpoint-next($name, $points);
    $max: breakpoint-max($next);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name, $points) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($next, $points) {
            @content;
        }
    }
}
