.slider-detail {
    .slider-detail-content {
        .mat-drawer-inner-container {
            // overflow: hidden;

            .wrapper-slider-detail {
                width: calc(100% - $width-menu);
                box-shadow: 0px 8px 10px -5px rgb($black, 20%), 0px 16px 24px 10px rgb($black, 14%),
                    0px 6px 30px 5px rgb($black, 12%);

                .list-residents {
                    > .card-list-menu {
                        img {
                            @apply ml-4;
                        }
                    }
                }
            }
        }
    }
}
