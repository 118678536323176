// 1.Color
// 2.Font Size
// 2 Breakpoints
// 3.mixin

// 1. Color
// ---------

// ----- root color -----
$black: #000000;
$white: #ffffff;
$red-cdg: #bd0d0e;
$border-edge: 1px solid var(--bg-EF);

$border-color: 1px solid #d6d6d6;
$redius: 8px;
$width-menu: 56px;
$width-menu-open: 280px;
$width-app: 320px;
$dialog-custom-autocomp: 450px;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px,
);
