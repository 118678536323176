.card-list-menu {
    &.mat-button {
        margin: 0 4px;
        width: calc(#{$width-menu} - 8px);
        height: calc(#{$width-menu} - 8px);

        > .mat-button-wrapper {
            overflow: hidden;
        }

        &.active {
            height: $width-menu;

            > .mat-button-wrapper {
                width: 100%;
            }
        }
    }
}
