.header-menu {
    box-shadow: 0px 0px 4px rgba($black, 0.1);

    .button-app {
        width: calc(#{$width-menu} - 8px);
        height: calc(#{$width-menu} - 8px);
    }

    > .menu-left {
        width: $width-menu;
        transition: width ease 0.3s;

        .logo {
            width: 48px;
            height: 48px;
        }

        > .menu-section-top,
        > .menu-section-bottom {
        }

        > .menu-section-top {
            /* width */
            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 5px grey;
                // border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: red;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #b30000;
            }
        }
    }

    > .menu-right {
        width: 0;
        transition: width ease 0.3s;

        &.active {
            width: $width-app;
        }
    }

    &.active {
        > .menu-left {
            width: $width-menu-open;

            > .menu-section-top,
            > .menu-section-bottom {
            }
        }
    }
}
